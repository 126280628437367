<template>
  <div :class="{'border-b': hasBorder}" class="border-dashed border-romanSilver mb-5 pb-5">
    <p class="font-semibold text-sm text-darkPurple mb-2">{{ title }}</p>
    <div class="flex justify-between items-start w-full gap-2">
      <div class="w-4/12 h-60 rounded-md text-blueCrayola font-normal">
          <img class="h-56 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
          <div v-else class="h-56 border rounded-md flex justify-center items-center">
          <span style="font-size:60px">
            {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
          </span>
          </div>
        </div>
      <div class="w-8/12 flex flex-col justify-start items-start gap-2">
        <div class="w-full flex flex-col justify-start items-start gap-2">
          <h4 class="font-bold text-lg leading-5 text-darkPurple">
            {{ employeeInfo.fname }} {{ employeeInfo.lname }}
          </h4>
          <div class="flex flex-col justify-start items-center w-full gap-1.5">
            <div class="flex justify-start items-start w-full">
              <p class="w-3/5 font-extrabold text-xs uppercase leading-5 text-romanSilver">
                Function:
              </p>
              <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                <span v-if="employeeInfo.orgFunction">
                  {{ employeeInfo.orgFunction.name || '-' }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-3/5 font-extrabold text-xs uppercase leading-5 text-romanSilver">
                Designation:
              </p>
              <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                <span v-if="employeeInfo.userDesignation">
                  {{ employeeInfo.userDesignation.name || '-' }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-3/5 font-extrabold text-xs uppercase leading-5 text-romanSilver">
                Job Level:
              </p>
              <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                <span v-if="employeeInfo.userLevel">
                  {{ employeeInfo.userLevel.name || '-' }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-3/5 font-extrabold text-xs uppercase leading-5 text-romanSilver">
                Office Location:
              </p>
              <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                <span v-if="employeeInfo.employeeOffice">
                  {{ employeeInfo.employeeOffice.name || '-' }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-3/5 font-extrabold text-xs uppercase leading-5 text-romanSilver">
                Reporting to
              </p>
              <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
               <span v-if="employeeInfo.userReportingTo">
                  {{ employeeInfo.userReportingTo.fname || '-' }} {{ employeeInfo.userReportingTo.lname || '-' }}
               </span>
               <span v-else>-</span>
              </p>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-3/5 font-extrabold text-xs uppercase leading-5 text-romanSilver">
                Pay Range:
              </p>
              <div class="w-2/5">
                <p class="font-semibold text-sm uppercase leading-5 text-darkPurple" v-if="employeeInfo.payInfo">
                  <span v-if="employeeInfo.payInfo.rangeMinimum || employeeInfo.payInfo.rangeMaximum">
                    {{ convertToCurrency(employeeInfo.payInfo.rangeMinimum) }} - {{ convertToCurrency(employeeInfo.payInfo.rangeMaximum) }}
                  </span>
                  <span v-else>-</span>
                </p>
                <span v-else>{{ '-' }}</span>
              </div>
            </div>
            <div class="flex justify-start items-start w-full">
              <p class="w-3/5 font-extrabold text-xs uppercase leading-5 text-romanSilver">
                Gross Annual Salary:
              </p>
              <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                {{ employeeInfo.payInfo.grossPay ? convertToCurrency(employeeInfo.payInfo.grossPay) : convertToCurrency(employeeInfo.grossPay) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      employeeInfo: {
        type: Object,
        default: () => {}
      },
      title: {
        type: String,
        default: 'Employee Details',
      },
      hasBorder: {
        type: Boolean,
        default: true,
      },
    }
  }
</script>

